import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import './index.scss';

import {
  Section,
  Container,
  Row,
  Column,
  Image,
  Text,
  Link,
} from 'components/atoms';

const MainpageLogotypes = ({ data }) => {
  const { awards, id, css_id } = data;

  return (
    <Section className={cn('mainpage-logotypes')} id={css_id || id}>
      <Container>
        <Row>
          <Column className={cn('logotypes', 'logotypes--mainpage')} col="12">
            {awards &&
              awards.map(
                (
                  {
                    counter,
                    link,
                    logo: {
                      url: {
                        source_url,
                        alt_text,
                        title,
                        media_details: { width, height },
                      },
                    },
                  },
                  index
                ) => (
                  <div
                    className="logotypes__item logotypes__item--mainpage"
                    key={index}
                  >
                    <Text color="lavender" className="logotypes__item__number">
                      {counter.padStart('2', 0)}
                    </Text>
                    <Image
                      className="logotypes__item__image"
                      src={source_url}
                      alt={alt_text}
                      title={title}
                      width={width}
                      height={height}
                    />
                    {link && (
                      <Link
                        href={link}
                        isRouter={false}
                        className="logotypes__item__link"
                        target="_blank"
                      >
                        <Text>{title}</Text>
                      </Link>
                    )}
                  </div>
                )
              )}
          </Column>
        </Row>
      </Container>
    </Section>
  );
};

MainpageLogotypes.defaultProps = {
  className: '',
};

MainpageLogotypes.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};

export default MainpageLogotypes;
